import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {
  EmailComponentTemplate,
  EmailComponentTemplateCategory,
  UserEmailComponentTemplate,
  UserEmailComponentTemplateWithLoadingState,
} from '@Components/email-maker-block-manager/email-maker-block-manager.types';

interface StateProps {
  componentTemplates: EmailComponentTemplate[];
  userComponentTemplates: UserEmailComponentTemplateWithLoadingState[];
  componentCategories: EmailComponentTemplateCategory[];
  recentlySavedUserTemplate: UserEmailComponentTemplate | null;
  selectedBlockPanel: string | null;
}

const initialState: StateProps = {
  componentTemplates: [],
  userComponentTemplates: [],
  componentCategories: [],
  recentlySavedUserTemplate: null,
  selectedBlockPanel: null,
};

const emailMakerBlockManagerSlice = createSlice({
  name: 'emailMakerBlockManagerSlice',
  initialState,
  reducers: {
    setTemplates: (state, action: PayloadAction<EmailComponentTemplate[]>) => {
      state.componentTemplates = action.payload;
    },

    addTemplate: (state, action: PayloadAction<EmailComponentTemplate>) => {
      state.componentTemplates.unshift(action.payload);
    },

    setCategories: (state, action: PayloadAction<EmailComponentTemplateCategory[]>) => {
      state.componentCategories = action.payload;
    },

    setUserTemplates: (state, action: PayloadAction<UserEmailComponentTemplate[]>) => {
      state.userComponentTemplates = action.payload.map((template): UserEmailComponentTemplateWithLoadingState => {
        return {...template, isPreviewGenerated: true};
      });
    },

    setRecentlySavedUserTemplate: (state, action: PayloadAction<UserEmailComponentTemplate | null>) => {
      state.recentlySavedUserTemplate = action.payload;
    },

    addUserTemplate: (state, action: PayloadAction<UserEmailComponentTemplate>) => {
      state.userComponentTemplates.unshift({...action.payload, isPreviewGenerated: false});
      state.recentlySavedUserTemplate = action.payload;
      state.selectedBlockPanel = window.PMW.emails.blocks.CATEGORY_SECTIONS;
    },

    removeUserTemplate: (state, action: PayloadAction<number>) => {
      state.userComponentTemplates = state.userComponentTemplates.filter((template) => {
        return template.id !== action.payload;
      });
    },

    updateUserTemplateName: (state, action: PayloadAction<{templateId: number; newName: string}>) => {
      const {templateId, newName} = action.payload;
      const template = state.userComponentTemplates.find((template) => template.id === templateId);
      if (template) {
        template.name = newName;
      }
    },

    setUserTemplatePreviewLoaded: (state, action: PayloadAction<number>) => {
      const template = state.userComponentTemplates.find((template) => template.id === action.payload);
      if (template) {
        template.isPreviewGenerated = true;
      }
    },

    setSelectedBlockPanel: (state, action: PayloadAction<string | null>) => {
      state.selectedBlockPanel = action.payload;
    },
  },
});

export const {
  setTemplates,
  addTemplate,
  setCategories,
  setUserTemplates,
  setRecentlySavedUserTemplate,
  addUserTemplate,
  removeUserTemplate,
  updateUserTemplateName,
  setUserTemplatePreviewLoaded,
  setSelectedBlockPanel,
} = emailMakerBlockManagerSlice.actions;

export const emailMakerBlockManagerReducer = emailMakerBlockManagerSlice.reducer;
