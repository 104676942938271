import {hideLoadingAction, showLoadingAction, updateLoadingProgressAction, updateLoadingTextAction} from "../components/loading-toast/index.ts";

/**
 * A map to keep track of which loadings will appear after a delay and cancel that incase hide is called
 * @type {{}}
 */
let timeoutMap = {};

/**
 * Used to show a loading toast with progress bar at 90%
 * @type {number}
 */
export const PROGRESS_ALMOST_COMPLETE = 0.9;

/**
 * Shows the loading toast
 * @param {string} key
 * @param {Object} [opts]
 */
export const showLoading = (key, opts = {}) => {
    if (key === undefined) {
        return;
    }

    opts.key = key;
    opts.priority = opts.priority ?? 0;
    opts.text = opts.text ?? i18next.t('pmwjs_loading');
    opts.hideIcon = opts.hideIcon ?? false;
    opts.progress = opts.progress ?? null;
    opts.delay = opts.delay ?? 0;
    opts.variant = opts.variant ?? 0;

    if (opts.delay) {
        if (timeoutMap[opts.key]) {
            clearTimeout(timeoutMap[key]);
        }

        timeoutMap[opts.key] = setTimeout(() => {
            PMW.redux.store.dispatch(showLoadingAction(opts));
        }, opts.delay);
    } else {
        PMW.redux.store.dispatch(showLoadingAction(opts));
    }
}

/**
 * Hides the loading toast for the key
 * @param {string} key
 */
export const hideLoading = (key) => {
    if (timeoutMap[key]) {
        clearTimeout(timeoutMap[key]);
        delete timeoutMap[key];
    }
    PMW.redux.store.dispatch(hideLoadingAction(key));
}

/**
 * Progress handler for a progress bar. Goes up to 90%, leaving 10% for server side processing
 * @param {string} loadingKey
 * @param {object} e EventData
 */
export const eventProgressHandler = (loadingKey, e) => {
    if (e.lengthComputable) {
        // Leave 10% for server side processing
        updateLoadingProgress(loadingKey, (e.loaded / (e.total * 1.1)));
    } else {
        // Unable to compute progress information since the total size is unknown
        // TODO have some sort of animated bar like the indeterminate mode of <progress>
        updateLoadingProgress(loadingKey, 0.9);
    }
}

/**
 * @param {string} key
 * @param {number} durationMs // How long you expect it to take
 * @param {number} steps // In how many steps it should be completed. The more steps the smoother.
 */
export const smoothEstimateLoadingProgress = (key, durationMs, steps) => {
    let progress = 0;
    const intervalTime = durationMs / steps; // Time per step
    const interval = setInterval(() => {
        progress += 1 / steps; // Increment progress smoothly
        updateLoadingProgress(key, Math.min(progress, 1)); // Ensure it doesn't exceed 1

        if (progress >= 1) {
            clearInterval(interval);
        }
    }, intervalTime);
}

/**
 * Update the loading progress
 * @param {string} key
 * @param {number} progress
 */
export const updateLoadingProgress = (key, progress) => {
    PMW.redux.store.dispatch(updateLoadingProgressAction(key, progress));
}

/**
 * Update the loading text
 * @param {string} key
 * @param {string} text
 */
export const updateLoadingText = (key, text) => {
    PMW.redux.store.dispatch(updateLoadingTextAction(key, text));
}

/**
 * Returns whether a loader with the given key is active
 * @param {string} key
 * @returns {boolean}
 */
export const isLoaderActiveForKey = (key) => {
    const state = PMW.redux.store.getState().loadingToast,
        isAnyLoaderActive = state.loadings.length > 0;

    if (isAnyLoaderActive) {
        return typeof state.loadings.find((loader) => (
            loader.key === key
        )) !== 'undefined';
    }
    return false;
}
