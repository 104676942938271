import type {PayloadAction} from '@reduxjs/toolkit';
import {createSlice} from '@reduxjs/toolkit';
import type {SocialLinkSuggestions} from "@Components/email-maker-social-link-suggestions-dropdown/email-maker-social-link-suggestions-dropdown.types";

interface StateProps {
  socialLinkSuggestions: SocialLinkSuggestions;
}

const initialState: StateProps = {
  socialLinkSuggestions: {
    businessProfiles: {},
    connectedAccounts: {}
  },
};

export const emailMakerSocialLinkSuggestionsDropdownSlice = createSlice({
  name: 'emailMakerSocialLinkSuggestionsDropdown',
  initialState,
  reducers: {
    updateSocialLinkSuggestions(state, action: PayloadAction<SocialLinkSuggestions>) {
      state.socialLinkSuggestions = action.payload;
    },
  },
});

export const {updateSocialLinkSuggestions} = emailMakerSocialLinkSuggestionsDropdownSlice.actions;
export const emailMakerSocialLinkSuggestionsDropdownReducer = emailMakerSocialLinkSuggestionsDropdownSlice.reducer;